@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-primary: 236 1 139;
    --color-primary-dark: 189 1 111;
    --color-primary-light: 240 52 162;

    --color-secondary: 105 48 255;
    --color-secondary-dark: 84 38 204;
    --color-secondary-light: 35 89 255;

    --color-tertiary: 95 82 156;
    --color-tertiary-dark: 93 90 136;
    --color-tertiary-light: 151 149 181;

    --color-background: 24 19 38;

    --logo-shadow: -0.15rem -0.15rem 0 rgb(var(--color-primary));
  }

  html {
    @apply text-zinc-50;
  }

  body {
    @apply bg-background;
  }
}

@layer components {
  .button {
    background-color: theme('colors.primary.DEFAULT');
    border-radius: theme('borderRadius.md');
    padding: theme('spacing[2.5]');
    font-weight: theme('fontWeight.medium');
    transition: all theme('transitionDuration.150')
      theme('transitionTimingFunction.in-out');
  }

  .button:hover {
    background-color: theme('colors.primary.light');
    box-shadow: theme('boxShadow.lg');
  }

  .button:active {
    background-color: theme('colors.primary.light');
    box-shadow: theme('boxShadow.lg');
  }

  .button:disabled {
    transform: none !important;
    box-shadow: none;
    cursor: not-allowed;
    @apply bg-pink-800 text-zinc-400;
  }

  .input {
    color: theme('colors.black');
    border-radius: theme('borderRadius.md');
    padding: theme('spacing[2.5]');
    text-align: center;
  }

  .input[aria-invalid='true'] {
    @apply text-red-500 outline-none ring-2 ring-red-500 focus:ring-red-500;
  }

  .section {
    padding: theme('spacing.14') theme('spacing.6');
  }

  .section-title {
    font-size: theme('fontSize.2xl');
    font-weight: theme('fontWeight.bold');
    letter-spacing: theme('letterSpacing.wide');
    line-height: theme('lineHeight.none');
  }

  .grid-parent {
    perspective: 700px;
    perspective-origin: center top;
  }

  .grid-pattern {
    height: 100%;
    width: 100%;
    transform-style: preserve-3d;
    transform: rotateX(90deg);
    background-size: 50px 50px;

    background-image: linear-gradient(
        to right,
        rgb(var(--color-tertiary-light)) 1px,
        transparent 1px
      ),
      linear-gradient(
        to bottom,
        rgb(var(--color-tertiary-light)) 1px,
        transparent 1px
      );
  }

  @screen md {
    .section {
      padding: theme('spacing.20') theme('spacing.6') theme('spacing.36')
        theme('spacing.6');
    }

    .section-title {
      font-size: theme('fontSize.4xl');
    }
  }
}
